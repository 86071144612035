import './index.scss'
// import AnimatedLetters from '../AnimatedLetters'
// import { useEffect, useState } from 'react'
import ArtistImage from '../../assets/images/about-me-artist.jpeg'
import TreeGuy from '../../assets/images/tree-guy.png'
import Mystery from '../../assets/images/Mystery.png'

const About = () =>  {
    // const [letterClass, setLetterclass] = useState('text-animate')

    // useEffect(() => {
    //     return setTimeout(() => {
    //         setLetterclass('text-animate-hover')
    //     }, 4000)
    // }, [])
    return (
        <div className='container about-page'>
            <div className='image-container-about'>
                <img src={ArtistImage} className='artist-image' alt='About Me' />
                <img src={TreeGuy} className='tree-guy-about' alt='Tree Guy' />
                <img src={Mystery} className='mystery-about' alt='Mystery' />
            </div>
            <div className='text-zone'>
                <h1>
                    {/* <AnimatedLetters
                        letterClass={letterClass}
                        strArray={['A', 'b', 'o', 'u', 't', ' ', 'M', 'e']}
                        idx={15}
                    /> */}
                    About Me
                </h1>
                <p>
                    My name is Dalvion, also known artistically as King x Indigo! My artstyle aims to be<br/>
                    simultaneously both dark and light, both cute and eerie. I want to represent both sides of my<br/>
                    personality, choosing to focus on themes of self acceptance, mental health and our<br/>
                    environment through the lense of my characters and surrealist expressions. My work ranges<br/>
                    from acrylic and multimedia paintings to merchandising and designing multiple products<br/>
                    including shirts, lighters and prints etc.
                </p>
                <p>
                    I aim to create more honest spaces of emotional expression and mental health/ wellness<br/>
                    through my art and creativity because I believe that the world would be better and brighter if<br/>
                    people expressed themselves more honestly and openly.<br/>
                    Let's change the game together! Follow my journey on my patreon or my socials for more ✨
                </p>
                {/* <p>
                    I'm a third paragrapgh
                </p> */}
            </div>
            
        </div>
    )
}

export default About