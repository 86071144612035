import { Link } from 'react-router-dom';
// import { useState } from 'react'
import './index.scss';
import HomeArt from '../../assets/images/Anxiety.jpeg'

const Home = () => {
    // const [letterClass, setLetterClass] = useState('text-animate')
    // const nameArray = ['D', 'a', 'l', 'v','i', 'o', 'n']

    // useEffect(() => {
    //     return setTimeout(() => {
    //         setLetterClass('text-animate-hover')
    //     }, 4000)
    // }, [])

    return (
        <div className="container home-page">
            <div className="text-zone">
                <h1>King x Indigo</h1>
                <h2 className='one-liner'>Welcome to the forest that is my mind</h2>
                {/* <AnimatedLetters letterClass={letterClass} strArray={nameArray} idx={15} /> */}
                <h2>Visual Artist / Designer from HTX</h2>
                <Link to='/contact' className='flat-button'>CONTACT ME</Link>
            </div>
            <img src={HomeArt} className='home-art' alt='Tree Guy' />
        </div>
    )

}

export default Home