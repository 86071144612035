import './index.scss';
import Dropdown from '../Dropdown';
import CloseIcon from '@mui/icons-material/Close';
import React,{ useState } from 'react';
import Images from '../../data/images';

const Gallery = () => {

    const [selected,setSelected] = useState("All");
    const [model, setModel] = useState(false);
    const [tempImgSrc, setTempImgSrc] = useState('');
    // const [images, setImages] = useState(Images);

    const getImg = (imgSrc) => {
        setTempImgSrc(imgSrc);
        setModel(true);
        // window.scrollTo(0, 0); //Scrolls to top
        // console.warn(imgSrc);
    };

    //Filter Images
    const filteredImages = selected === "All" ? Images : Images.filter(img => img.category === selected);

    // useEffect(() => {
    //     const pictures = document.querySelectorAll('.pictures');
    //     pictures.forEach(pic => pic.classList.add('fade-out'));

    //     setTimeout(() => {
    //         setImages(selected === "All" ? Images : Images.filter(img => img.category === selected));
    //     }, 350);

    //     setTimeout (() => {
    //         pictures.forEach(pic => pic.classList.remove('fade-out'));
    //         pictures.forEach(pic => pic.classList.add('fade-in'));
    //     }, 360)
    // }, [selected])

    return (
        <>
            <div className='container contact-page'>
                <div className='gallery-zone'>
                    <h1 className='page-title'>Gallery</h1>
                    <Dropdown selected={selected} setSelected={setSelected}/>
                    <div className={model ? "model open" : "model"}>
                        <img src={tempImgSrc} alt='Model'/>
                        <CloseIcon onClick={()=> setModel(false)} />
                    </div>
                    <div className='gallery'>
                        {filteredImages.map((item, index) => {
                            return (
                                <div className='pictures' key={index} onClick={() => getImg(item.imgSrc)}>
                                    <img src={item.imgSrc} style={{width: '100%'}} alt='Gallery'/>
                                </div>
                            );
                        })};

                    </div>
                    
                </div>
            </div>
        </>
    );
};

export default Gallery