
import GalleryImage1 from '../assets/images/gallery/conflict-of-interest-2024.jpeg';
import GalleryImage2 from '../assets/images/gallery/between-light-and-dark.jpeg';
import GalleryImage3 from '../assets/images/gallery/upflow-2024.jpeg';
import GalleryImage4 from '../assets/images/gallery/tree-huggers.jpeg';
import GalleryImage5 from '../assets/images/gallery/overgrowth-overture.jpeg';
import GalleryImage6 from '../assets/images/gallery/refuse-to-die.jpeg';
import GalleryImage7 from '../assets/images/gallery/communication.jpeg';
import GalleryImage8 from '../assets/images/gallery/I-feel-everything-2023.jpeg';
import GalleryImage9 from '../assets/images/gallery/hidden-lense-1.jpeg';
import GalleryImage10 from '../assets/images/gallery/anxiety.jpeg';
import GalleryImage11 from '../assets/images/gallery/beach-day.jpeg.png';
import GalleryImage12 from '../assets/images/gallery/collectible-commission.jpeg';
import GalleryImage13 from '../assets/images/gallery/cornbread-cover-for-natedrop.jpeg';
import GalleryImage14 from '../assets/images/gallery/cute-but-deadly.jpeg';
import GalleryImage15 from '../assets/images/gallery/cyclebreaker-shirts.jpeg';
import GalleryImage16 from '../assets/images/gallery/earth-angel.jpg';
import GalleryImage17 from '../assets/images/gallery/even-when-im-not-with-you.jpeg';
import GalleryImage18 from '../assets/images/gallery/face-towards-the-sun.jpg';
import GalleryImage19 from '../assets/images/gallery/flash-sheet.jpeg';
import GalleryImage20 from '../assets/images/gallery/food-commission.jpeg';
import GalleryImage21 from '../assets/images/gallery/hidden-lense-2.jpeg';
import GalleryImage22 from '../assets/images/gallery/hidden-lense-3.jpeg';
import GalleryImage23 from '../assets/images/gallery/leftovers.jpeg';
import GalleryImage24 from '../assets/images/gallery/lost-and-found-2022.jpeg';
import GalleryImage25 from '../assets/images/gallery/love-and-fight-shirts.jpeg';
import GalleryImage26 from '../assets/images/gallery/love-not-war-prints.jpeg';
import GalleryImage27 from '../assets/images/gallery/magic-table.jpeg';
import GalleryImage28 from '../assets/images/gallery/mixed-emotions.jpeg';
import GalleryImage29 from '../assets/images/gallery/moment-of-clarity-2024.jpeg';
import GalleryImage30 from '../assets/images/gallery/my-forest-spirit-characters.jpeg';
import GalleryImage31 from '../assets/images/gallery/rather-laugh-than-cry.jpeg';
import GalleryImage32 from '../assets/images/gallery/to-live-to-die-2022.png';
import GalleryImage33 from '../assets/images/gallery/relationships-2021.jpeg';
import GalleryImage34 from '../assets/images/gallery/stay-weird-forever.jpeg';
import GalleryImage35 from '../assets/images/gallery/stay-weird.jpeg';
import GalleryImage36 from '../assets/images/gallery/TRAPS-2023.jpeg';
import GalleryImage37 from '../assets/images/gallery/two-way-mirror.jpeg';
import GalleryImage38 from '../assets/images/gallery/the-times.jpeg';
import GalleryImage39 from '../assets/images/gallery/iridescent-allure.png';
import GalleryImage40 from '../assets/images/gallery/MIGRAIN3.png';
import GalleryImage41 from '../assets/images/gallery/skybloom-2022.png';
import GalleryImage42 from '../assets/images/gallery/fall-apart.png';

const Images = [
    { id: 1, imgSrc: GalleryImage1, category: 'Painted' },
    { id: 2, imgSrc: GalleryImage2, category: 'Painted' },
    { id: 3, imgSrc: GalleryImage3, category: 'Painted' },
    { id: 4, imgSrc: GalleryImage4, category: 'Digital' },
    { id: 5, imgSrc: GalleryImage5, category: 'Digital' },
    { id: 6, imgSrc: GalleryImage6, category: 'Digital' },
    { id: 7, imgSrc: GalleryImage7, category: 'Digital' },
    { id: 8, imgSrc: GalleryImage8, category: 'Painted' },
    { id: 9, imgSrc: GalleryImage9, category: 'Digital' },
    { id: 10, imgSrc: GalleryImage10, category: 'Digital' },
    { id: 11, imgSrc: GalleryImage11, category: 'Digital' },
    { id: 12, imgSrc: GalleryImage12, category: 'Painted' },
    { id: 13, imgSrc: GalleryImage13, category: 'Digital' },
    { id: 14, imgSrc: GalleryImage14, category: 'Digital' },
    { id: 15, imgSrc: GalleryImage15, category: 'Digital' },
    { id: 16, imgSrc: GalleryImage16, category: 'Digital' },
    { id: 17, imgSrc: GalleryImage17, category: 'Digital' },
    { id: 18, imgSrc: GalleryImage18, category: 'Digital' },
    { id: 19, imgSrc: GalleryImage19, category: 'Digital' },
    { id: 20, imgSrc: GalleryImage20, category: 'Digital' },
    { id: 21, imgSrc: GalleryImage21, category: 'Digital' },
    { id: 22, imgSrc: GalleryImage22, category: 'Digital' },
    { id: 23, imgSrc: GalleryImage23, category: 'Digital' },
    { id: 24, imgSrc: GalleryImage24, category: 'Painted' },
    { id: 25, imgSrc: GalleryImage25, category: 'Digital' },
    { id: 26, imgSrc: GalleryImage26, category: 'Digital' },
    { id: 27, imgSrc: GalleryImage27, category: 'Painted' },
    { id: 28, imgSrc: GalleryImage28, category: 'Digital' },
    { id: 29, imgSrc: GalleryImage29, category: 'Painted' },
    { id: 30, imgSrc: GalleryImage30, category: 'Digital' },
    { id: 31, imgSrc: GalleryImage31, category: 'Digital' },
    { id: 32, imgSrc: GalleryImage32, category: 'Painted' },
    { id: 33, imgSrc: GalleryImage33, category: 'Painted' },
    { id: 34, imgSrc: GalleryImage34, category: 'Digital' },
    { id: 35, imgSrc: GalleryImage35, category: 'Digital' },
    { id: 36, imgSrc: GalleryImage36, category: 'Painted' },
    { id: 37, imgSrc: GalleryImage37, category: 'Digital' },
    { id: 38, imgSrc: GalleryImage38, category: 'Digital' },
    { id: 39, imgSrc: GalleryImage39, category: 'Painted' },
    { id: 40, imgSrc: GalleryImage40, category: 'Painted' },
    { id: 41, imgSrc: GalleryImage41, category: 'Painted' },
    { id: 42, imgSrc: GalleryImage42, category: 'Digital' },
];

export default Images;