import { useRef } from 'react'
import emailjs from '@emailjs/browser'
import './index.scss'
import HeartIcon from '../../assets/images/king-x-indigo-heart-logo-tranparent.png';
import LogoSubtitle from '../../assets/images/king-x-indigo-logo-transparent.png';

    const Contact = () => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs
            .sendForm('service_8hbt73l', 'template_afkedob', form.current, {
            publicKey: 'x8ytbWF6nK7SGBp5F',
            })
            .then(
            () => {
                console.log('SUCCESS!');
            },
            (error) => {
                console.log('FAILED...', error.text);
            },
            );
        };


    return (
        <div className='container contact-page'>
            <div className='contact-zone'>
                <h1>Contact me</h1>
                <p>
                    If you have any questions or would like to contact me to discuss more, feel free to write me a message below!
                </p>
                <div className='contact-form'>
                    <form ref={form} onSubmit={sendEmail}>
                        <ul>
                            <li className='half'>
                                <input type='text' name='from_name' placeholder='Name' required />
                            </li>
                            <li className='half'>
                                <input type='email' name='email_id' placeholder='Email' required />
                            </li>
                            <li>
                                <input type='text' name='subject' placeholder='Subject' />
                            </li>
                            <li>
                                <textarea name='message' placeholder='Message' required></textarea>
                            </li>
                            <li>
                                <input type='submit' className='flat-button' value="SEND" />
                            </li>
                        </ul>
                    </form>
                </div>
                <img src={HeartIcon} className='tree-guy-contact' alt='Tree Guy' />
                <img src={LogoSubtitle} className='logo-contact' alt='Tree Guy' />
            </div>
        </div>
    )
}

export default Contact