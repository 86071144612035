// import logo from './logo.svg';
import './App.scss';
import { Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './components/Home';
import About from './components/About'
import Calendar from './components/Calendar'
import Contact from './components/Contact'
import Gallery from './components/Gallery'
import { useEffect } from 'react';


function App() {
  useEffect(() => {
    document.title = "King xIndigo";
  }, [])
  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        <Route index element={<Home />} />
        <Route path='about' element={<About />} />
        <Route path='calendar' element={<Calendar />} />
        <Route path='contact' element={<Contact />} />
        <Route path='gallery' element={<Gallery />} />
      </Route>
    </Routes>
    // <----------DEFAULT CODE------------>
    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.js</code> and save to reload.
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
  );
}

export default App;
