import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

const Dropdown = ({ selected, setSelected }) => {
    const [isActive, setIsActive] = useState(false);
    const options = ["All", "Digital", "Painted"];
    return (
        <div className="dropdown">
            <div className="dropdown-btn" onClick={e => setIsActive(!isActive)}>
                {selected}
                <FontAwesomeIcon icon={faCaretDown} color='#4d4d4e'/>
            </div>
            {isActive && (
            <div className="dropdown-content">
                {options.map((option) => (
                    <div 
                        key={option}
                        onClick={(e) => {
                            setSelected(option);
                            setIsActive(false);
                        }}
                        className="dropdown-item">
                        {option}
                    </div>
                ))}
                {/* <div className="dropdown-item">All</div>
                <div className="dropdown-item">Digital</div>
                <div className="dropdown-item">Painted</div> */}
            </div>
            )}
        </div>
        // <select value={selectedCategory} onChange={(e) => onCategoryChange(e.target.value)}>
        //     <option value="">All</option>
        //     <option value="Digital">Digital</option>
        //     <option value="Painted">Painted</option>
        // </select>
    );
};

export default Dropdown;