import './index.scss';
import { useState } from 'react'
import LogoS from '../../assets/images/king-x-indigo-heart-logo-tranparent.png';
import HomeIcon from '../../assets/images/Home-button-cropped.png';
import CalendarIcon from '../../assets/images/calendar-icon-cropped.png';
import ContactIcon from '../../assets/images/Contact-button-cropped.png';
import GalleryIcon from '../../assets/images/Gallery-button-cropped.png';
import PatreonIcon from '../../assets/images/patreon-icon-cropped.png';
import ShopIcon from '../../assets/images/shop-button-2-cropped.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faClose } from '@fortawesome/free-solid-svg-icons';
import { Link, NavLink } from 'react-router-dom'
// import LogoSubtitle from '../../assets/images/king-x-indigo-logo-transparent.png';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faCalendar, faCartShopping, faEnvelope, faHome, faUser } from '@fortawesome/free-solid-svg-icons';
// import { faInstagram, faPatreon } from '@fortawesome/free-brands-svg-icons';

const Sidebar = () => {

    const [showNav, setShowNav] = useState(false);

    const closeNav = () => setShowNav(false);

    return (
        <div className='nav-bar'>
            <Link className="logo" to='/'>
                <img src={LogoS} alt='logo' />
                {/* <img className='sub-logo' src={LogoSubtitle} alt='kingxindigo' /> */}
            </Link>
            <nav className={showNav ? 'mobile-show' : ''}>

                {/* Internal Links */}

                <NavLink onClick={closeNav} exact="true" className="about-link" to="/about">
                    {/* <FontAwesomeIcon icon={faUser} color='#4d4d4e'/> */}
                    <span  className='logo2'><img className='logo' src={CalendarIcon} alt='logo' /></span>
                </NavLink>
                <NavLink onClick={closeNav} exact="true" className="contact-link" to="/contact">
                    {/* <FontAwesomeIcon icon={faEnvelope} color='#4d4d4e'/> */}
                    <span className='logo2'><img className='logo' src={ContactIcon} alt='logo' /></span>
                </NavLink>
                <NavLink onClick={closeNav} exact="true" className="gallery-link" to="/gallery">
                    {/* <FontAwesomeIcon icon={faCalendar} color='#4d4d4e'/> */}
                    {/* <img src={HomeIcon} alt='logo' /> */}
                    <span href="" className='logo2'><img className='logo' src={GalleryIcon} alt='logo' /></span>
                </NavLink>
            
                {/* External Links */}

                <a target="_blank" rel='noreferrer' className="etsy-link"  href="https://www.etsy.com/shop/WolfIndigoShop?listing_id=921673495&ref=related#items">
                    <img className='anchor-logo' src={ShopIcon} alt='logo' />
                </a>
                <a target="_blank" rel='noreferrer' className="patreon-link" href="https://www.patreon.com/KingxIndigocorner">
                    {/* <FontAwesomeIcon icon={faPatreon} color='#4d4d4e'/> */}
                    <img className='anchor-logo' src={PatreonIcon} alt='logo' />
                </a>
                <a target="_blank" rel='noreferrer' className="linktree-link" href="https://linktr.ee/Wolf_Indigo">
                    {/* <FontAwesomeIcon icon={faPatreon} color='#4d4d4e'/> */}
                    <img className='anchor-logo' src={HomeIcon} alt='logo' />
                </a>

                {/* Close Button */}

                <FontAwesomeIcon
                    onClick={closeNav}
                    icon={faClose}
                    color='#ffd700'
                    size='3x'
                    className='close-icon'
                />
            </nav>
            
            {/* Hamburger Menu */}

            <FontAwesomeIcon
                onClick={() => setShowNav(true)}
                icon={faBars}
                color='#ffd700'
                size='3x'
                className='hamburger-icon'
            />
        </div>
    )
}

export default Sidebar