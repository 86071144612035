import './index.scss'

const Calendar = () => {
    return (
        <div className='container contact-page'>
            <div className='text-zone'>
                <h1>Calendar</h1>
                
            </div>
        </div>
    )
}

export default Calendar